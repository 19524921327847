<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="shopBig">
            <div class="shopMsg">
                <div class="shopLeft">
                    <!-- <img :src="dataMsg.pic[0]" alt="" /> -->
                    <el-carousel height="6.75rem" :show-indicators="false">
                        <el-carousel-item
                            v-for="(item, index) in dataMsg.pic"
                            :key="index"
                        >
                            <img :src="item" alt="" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="shopCont">
                    <div>
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                            "
                        >
                            <div class="shopName">
                                {{ dataMsg.title }}
                            </div>
                            <!-- <div
                            class="aixin"
                            v-if="dataMsg.isCollect == 0"
                            @click="isCollectSet"
                        >
                            <img src="../assets/aixinNo.png" alt="" />
                        </div>
                        <div
                            class="aixin aixinA"
                            v-if="dataMsg.isCollect == 1"
                            @click="isCollectSet"
                        >
                            <img src="../assets/aixin.png" alt="" />
                        </div> -->
                        </div>

                        <div class="tipBox">
                            <div class="tipLi">库存：{{ dataMsg.stock }}</div>
                            <div class="tipLi">产地：{{ dataMsg.source }}</div>
                        </div>
                        <div class="shopPrice">
                            <span>¥</span
                            ><span class="shopPriceNum">{{
                                dataMsg.price
                            }}</span>
                        </div>
                        <div class="shopA" @click="toUrl('/companyXq')">
                            <div class="shopALeft">
                                商家<span>
                                    {{ dataMsg.companyName || '' }}</span
                                >
                            </div>
                            <div class="el-icon-arrow-right"></div>
                        </div>
                    </div>
                    <!-- @click="kefuFlag = true" -->
                    <div class="shopBottom">
                        <div class="shopBottomLeft">
                            <div class="shopBottomLeftLi" @click="kefuGo">
                                <img src="../assets/shopKf.png" alt="" />
                                <div class="shopBottomLeftName">客服</div>
                            </div>
                            <div
                                class="shopBottomLeftLi"
                                v-if="dataMsg.isCollect == 0"
                                @click="isCollectSet"
                            >
                                <img src="../assets/shopSc.png" alt="" />
                                <div class="shopBottomLeftName">收藏</div>
                            </div>
                            <div
                                class="shopBottomLeftLi"
                                v-if="dataMsg.isCollect == 1"
                                @click="isCollectSet"
                            >
                                <img src="../assets/shopScA.png" alt="" />
                                <div class="shopBottomLeftName">收藏</div>
                            </div>
                        </div>
                        <div
                            class="shopBottomBtn"
                            @click="specificationFlag = true"
                        >
                            <div class="shopBottomBtnLi">加入购物车</div>
                            <div class="shopBottomBtnLi shopBottomBtnLiA">
                                立即购买
                            </div>
                        </div>
                    </div>
                    <!-- <div class="shopPrice">库存：{{ dataMsg.stock }}</div>
                    <div class="shopPrice">产地：{{ dataMsg.source }}</div> -->
                </div>
            </div>
        </div>
        <div class="js">
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                "
            >
                <div class="more"></div>

                <div class="shopTit">
                    <div class="shopDiv">
                        <img src="../assets/titLeft.png" alt="" />
                    </div>
                    <div class="shopName">产品评价</div>
                    <div class="shopDiv">
                        <img src="../assets/titRight.png" alt="" />
                    </div>
                </div>
                <div class="more" @click="toUrlMore">更多评价</div>
            </div>

            <div
                class="evaluateList"
                v-for="(item, index) in dataMsg.commentList"
                :key="index"
            >
                <div class="evaluatePerson">
                    <div class="evaluatePersonImg">
                        <img :src="item.userUrl" alt="" />
                    </div>
                    <div class="evaluatePersonName">{{ item.userName }}</div>
                </div>
                <div class="evaluateGg">规格：{{ item.goodsSpecName }}</div>
                <div class="evaluateComent">{{ item.comment }}</div>
                <div class="evaluateImgList">
                    <div
                        class="evaluateImgListLi"
                        v-for="(obj, objIndex) in item.img"
                        :key="objIndex"
                    >
                        <img :src="obj" alt="" />
                    </div>

                    <div
                        class="evaluateImgListLi"
                        v-for="(obj, objIndex) in item.videos"
                        :key="objIndex"
                    >
                        <video :src="obj" controls></video>
                    </div>
                </div>
                <div class="evaluateTime">
                    {{ item.createDate }}
                </div>
            </div>
        </div>
        <div class="js">
            <div class="shopTit">
                <div class="shopDiv">
                    <img src="../assets/titLeft.png" alt="" />
                </div>
                <div class="shopName">产品介绍</div>
                <div class="shopDiv">
                    <img src="../assets/titRight.png" alt="" />
                </div>
            </div>
            <div
                class="fwb"
                v-for="(item, index) in dataMsg.details"
                :key="index"
            >
                <!-- <div v-html="dataMsg.content"></div> -->
                <img :src="item" alt="" />
            </div>
            <!-- <div
                class="shenq"
                style="background: #999"
                v-if="userInfo.userType == 4 || userInfo.userType == 0"
            >
                申请
            </div>
            <div
                class="shenq"
                @click="open"
                v-if="
                    userInfo.userType == 1 ||
                    userInfo.userType == 2 ||
                    userInfo.userType == 3
                "
            >
                申请
            </div> -->
        </div>
        <!-- <FooterBottom></FooterBottom> -->

        <div class="popupBig" v-if="kefuFlag">
            <div class="popupBox">
                <div class="popupTop">
                    <div></div>
                    <div class="popupTit">客服</div>
                    <div class="el-icon-close" @click="kefuFlag = false"></div>
                </div>
                <div class="popupLi">
                    <div class="popupLeft">
                        <img src="../assets/kefudh.png" alt="" />
                        <div class="popupCont">
                            <div class="popupContTit">商家客服</div>
                            <div class="popupContTip">使用、发货等咨询</div>
                        </div>
                    </div>
                    <div class="el-icon-arrow-right"></div>
                </div>
                <div class="popupLi">
                    <div class="popupLeft">
                        <img src="../assets/kefult.png" alt="" />
                        <div class="popupCont">
                            <div class="popupContTit">平台在线客服</div>
                            <div class="popupContTip">平台客服在线咨询</div>
                        </div>
                    </div>
                    <div class="el-icon-arrow-right"></div>
                </div>

                <div class="popupLi">
                    <div class="popupLeft">
                        <img src="../assets/kefudh.png" alt="" />
                        <div class="popupCont">
                            <div class="popupContTit">平台热线客服</div>
                            <div class="popupContTip">投诉、售后等咨询</div>
                        </div>
                    </div>
                    <div class="el-icon-arrow-right"></div>
                </div>
                <div class="popupBottom">
                    平台服务时间：周一至周日 09:00 ~ 18:00
                </div>
                <div class="popupBottom">该商品享受粮之道服务</div>
            </div>
        </div>
        <SpeciFication
            v-if="specificationFlag"
            :msgData="dataMsg"
            @closeBtn="closeBtn"
            @guigeMsgGet="guigeMsgGet"
        ></SpeciFication>

        <el-dialog :visible.sync="dialogVisible" width="70%">
            <div style="width: 100%; height: 8rem">
                <iframe
                    :src="urlSrcKf"
                    frameborder="0"
                    width="100%"
                    height="100%"
                ></iframe>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getGoods,
    collect,
    addShoppingCart,
    userInfo
} from '@/api/user/index.js'
export default {
    data() {
        return {
            urlSrcKf: '',
            dialogVisible: false,
            specificationFlag: false,
            kefuFlag: false,
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            id: '',
            dataMsg: {},
            userInfo: JSON.parse(localStorage.getItem('userInfo'))
        }
    },
    created() {
        this.id = this.$route.query.id
        this.init()
    },
    methods: {
        toUrlMore() {
            this.$router.push({
                path: 'merchandiseEvaluateList',
                query: { id: this.dataMsg.id }
            })
        },
        kefuGo() {
            if (localStorage.getItem('token')) {
                userInfo().then((res) => {
                    console.log(res)
                    if (res.code == 0) {
                        let url =
                            'https://kf.liangzhidao.com/index/index/home?theme=7571f9' +
                            '&visiter_id=' +
                            res.data.id +
                            '&visiter_name=' +
                            res.data.username +
                            '&avatar=' +
                            res.data.headUrl +
                            '&business_id=1&groupid=0'
                        // window.open(url, '_blank')
                        this.urlSrcKf = url
                        localStorage.setItem(
                            'userInfo',
                            JSON.stringify(res.data)
                        )
                    }
                })
            } else {
                let url =
                    'https://kf.liangzhidao.com/index/index/home?theme=7571f9' +
                    '&visiter_id=' +
                    '' +
                    '&visiter_name=' +
                    '' +
                    '&avatar=' +
                    '' +
                    '&business_id=1&groupid=0'
                // window.open(url, '_blank')
                this.urlSrcKf = url
            }
            this.dialogVisible = true
        },
        closeBtn() {
            this.specificationFlag = false
        },
        toUrl(name) {
            if (this.dataMsg.companyId == -1) {
                this.$message({
                    message: '当前商品是个人发布',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
            } else {
                this.$router.push({
                    path: name,
                    query: { id: this.dataMsg.companyId }
                })
            }
        },
        open() {
            // this.popFlag=true
            this.$router.push({
                path: '/applyFor',
                query: { id: this.dataMsg.id, type: 2 }
            })
        },
        isCollectSet() {
            if (!localStorage.getItem('token')) {
                this.$message({
                    message: '请先登录',
                    type: 'warning',
                    customClass: 'messageBoxTip'
                })
                return
            }
            if (this.dataMsg.isCollect == 0) {
                this.dataMsg.isCollect = 1
            } else {
                this.dataMsg.isCollect = 0
            }
            collect({ type: 1, id: this.dataMsg.id }).then((res) => {
                console.log(res)
                if (res.code == 0) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        customClass: 'messageBoxTip'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                        customClass: 'messageBoxTip'
                    })
                }
            })
        },
        guigeMsgGet(e, num, number) {
            console.log(e, num, number)
            // num==1//加入购物车2市立即购买
            let data = JSON.parse(e)
            if (num == 1) {
                // 加入购物车
                addShoppingCart({ goodsSpecId: data.id, num: number }).then(
                    (res) => {
                        this.specificationFlag = false

                        if (res.code == 0) {
                            this.$message({
                                message: '加入购物车成功',
                                type: 'success',
                                customClass: 'messageBoxTip'
                            })
                        }
                        // else {
                        //     this.$message({
                        //         message: res.msg,
                        //         type: 'warning',
                        //         customClass: 'messageBoxTip'
                        //     })
                        // }
                    }
                )
            } else {
                // 立即购买

                this.showSpecifica = false
                // 立即购买
                this.$router.push({
                    path: '/sureOrder',
                    query: { ids: data.id, num: number, type: 2 }
                })
            }
        },
        init() {
            getGoods({ id: this.id }).then((res) => {
                console.log(res)
                if (res.code == 0) {
                    // 将你要渲染的数据进行字符串正则匹配
                    // res.data.content = res.data.content.replace(
                    //     /<img/g,
                    //     "<img style='max-width:100%;height:auto;'"
                    // )
                    res.data.pic = res.data.pic.split(',')
                    res.data.details = res.data.details.split(',')
                    if (res.data.commentList.length > 0) {
                        for (var a = 0; a < res.data.commentList.length; a++) {
                            if (res.data.commentList[a].img) {
                                res.data.commentList[a].img =
                                    res.data.commentList[a].img.split(',')
                            }
                            if (res.data.commentList[a].videos) {
                                res.data.commentList[a].videos =
                                    res.data.commentList[a].videos.split(',')
                            }
                        }
                    }
                    this.dataMsg = res.data
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 0.5rem 0;
}
.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.shopBig {
    width: 16.27rem;
    padding: 0.42rem;
    box-sizing: border-box;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    margin: 0.4rem auto 0;
}

.shopMsg {
    display: flex;
    .shopLeft {
        width: 6.75rem;
        height: 6.75rem;
        img {
            width: 100%;
            height: 100%;
            // border-radius: 0.31rem;
            object-fit: cover;
        }
    }
    .shopCont {
        flex: 1;
        margin-left: 0.54rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .shopName {
            color: #343231;
            font-size: 0.36rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            flex: 1;
        }
        .aixin {
            width: 0.41rem;
            height: 0.42rem;
            opacity: 1;
            display: flex;
            align-items: center;
            background: #00000066;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.05rem;
            img {
                width: 0.26rem;
                height: 0.25rem;
            }
        }
        .aixinA {
            background: #ffbe0033;
        }

        .tipBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-top: 0.2rem;
            .tipLi {
                color: #6b6b6b;
                font-size: 0.22rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
        }
        .shopPrice {
            color: #868588;
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.58rem;
            span {
                color: #ff0000;
                text-align: left;
                font-weight: 700;

                font-family: 'SourceHanSansCN';
            }
            .shopPriceNum {
                font-size: 0.36rem;
            }
        }
        .shopA {
            border-radius: 0.16rem;
            opacity: 1;
            border: 0.04rem solid #ffffff80;
            background: #f4f6fb;
            display: flex;

            align-items: center;
            justify-content: space-between;
            padding: 0.4rem;
            box-sizing: border-box;
            margin-top: 0.42rem;
            .shopALeft {
                color: #808080;
                font-size: 0.24rem;
                font-weight: 500;
                font-family: 'HarmonyOSSansSC';
                span {
                    color: #1b1b1b;
                    font-size: 0.24rem;
                    font-weight: 500;
                    font-family: 'HarmonyOSSansSC';
                    margin-left: 0.1rem;
                }
            }
            .el-icon-arrow-right {
                font-size: 0.24rem;
            }
        }
        .shopBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .shopBottomLeft {
                display: flex;
                align-items: center;
                .shopBottomLeftLi {
                    margin-right: 0.33rem;
                    img {
                        width: 0.43rem;
                        height: 0.43rem;
                        display: block;
                    }
                    .shopBottomLeftName {
                        color: #1b1b1b;
                        font-size: 0.22rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                    }
                }
            }
            .shopBottomBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                .shopBottomBtnLi {
                    width: 2.25rem;
                    height: 0.9rem;
                    border-radius: 1rem;
                    border: 0.02rem solid #00903d;
                    color: #00903d;
                    font-size: 0.32rem;
                    font-weight: 400;
                    font-family: 'SourceHanSansCN';
                    margin-left: 0.16rem;

                    display: flex;
                    align-items: center;

                    justify-content: center;
                }
                .shopBottomBtnLiA {
                    background: linear-gradient(
                        -41.4deg,
                        #00903d 0%,
                        #00b34a 100%
                    );
                    color: #ffffff;
                }
            }
        }
    }
}
.js {
    width: 16.27rem;
    border-radius: 0.31rem;
    opacity: 1;
    border: 0.01rem solid #eaeaea;
    background: #ffffff;
    margin: 0.4rem auto 0;
    padding: 0.62rem;
    box-sizing: border-box;
    .more {
        color: #6b6b6b;
        font-size: 0.22rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        width: 2rem;
        text-align: right;
    }
    .shopTit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.42rem;
        .shopDiv {
            img {
                width: 0.5rem;
                display: block;
            }
        }

        .shopName {
            color: #1b1b1b;
            font-size: 0.3rem;
            font-weight: 700;
            font-family: 'SourceHanSansCN';
            margin: 0 0.16rem;
        }
    }
    .evaluateList {
        border-bottom: 1px solid #dedede;
        padding: 0.25rem 0;
        .evaluatePerson {
            display: flex;
            align-items: center;
            .evaluatePersonImg {
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    display: block;
                }
            }

            .evaluatePersonName {
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
                margin-left: 0.15rem;
            }
        }

        .evaluateGg {
            color: #6b6b6b;
            font-size: 0.22rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.2rem;
        }

        .evaluateComent {
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.2rem;
            line-height: 0.4rem;
        }
        .evaluateImgList {
            display: flex;
            align-items: center;
            .evaluateImgListLi {
                width: 2rem;
                height: 2rem;
                margin-top: 0.3rem;
                margin-right: 0.2rem;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 0.1rem;
                }
                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 0.1rem;
                }
            }
        }
        .evaluateTime {
            font-size: 0.26rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            margin-top: 0.2rem;
        }
    }
    .fwb {
        // color: #343231;
        // font-size: 0.36rem;
        // font-weight: 700;
        // font-family: 'SourceHanSansCN';
        img {
            display: block;
            width: 100%;
            object-fit: cover;
        }
    }
    .shenq {
        width: 6.71rem;
        height: 0.98rem;
        border-radius: 1rem;
        opacity: 1;
        background: linear-gradient(-41.4deg, #00903d 0%, #00b34a 100%);
        color: #ffffff;
        font-size: 0.32rem;
        font-weight: 500;
        font-family: 'SourceHanSansCN';
        line-height: 0.98rem;
        text-align: center;
        margin: 0.5rem auto 0;
    }
}

.popupBig {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    .popupBox {
        width: 7.5rem;
        // height: 7.22rem;

        padding-bottom: 0.3rem;
        border-radius: 0.32rem;
        background: #ffffff;
        margin: 2rem auto 0;
        .popupTop {
            display: flex;
            align-items: center;

            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            .popupTit {
                color: #000000;
                font-size: 0.36rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
            .el-icon-close {
                color: #a6a6a6;
                font-size: 0.36rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
        }
        .popupLi {
            padding: 0.4rem 0.8rem;
            box-sizing: border-box;
            display: flex;

            align-items: center;
            justify-content: space-between;
            .popupLeft {
                display: flex;

                align-items: center;
                img {
                    // width: 0.74rem;
                    // height: 0.74rem;

                    width: 0.94rem;
                    height: 0.94rem;
                }
                .popupCont {
                    margin-left: 0.19rem;
                    .popupContTit {
                        color: #1b1b1b;
                        font-size: 0.28rem;
                        font-weight: 700;
                        font-family: 'SourceHanSansCN';
                    }
                    .popupContTip {
                        color: #6b6b6bcc;
                        font-size: 0.24rem;
                        font-weight: 400;
                        font-family: 'SourceHanSansCN';
                    }
                }
            }
            .el-icon-arrow-right {
                font-size: 0.5rem;

                font-weight: 700;

                color: #6b6b6bcc;
            }
        }
        .popupBottom {
            color: #585858;
            font-size: 0.24rem;
            font-weight: 400;
            font-family: 'SourceHanSansCN';
            text-align: center;
        }
    }
}
</style>