<template>
    <div class="content">
        <div class="bgColor"></div>
        <HeaderTop
            :parentData="dataNum"
            :background="background"
            :color="color"
            :activeColor="activeColor"
            :position="position"
        ></HeaderTop>
        <div class="chooseBig">
            <div class="chooseTab">
                <div
                    class="chooseTabLi"
                    :class="tabNum == 99 ? 'chooseTabLiA' : ''"
                    @click="shoose(99)"
                >
                    全部
                </div>
                <div class="chooseLine"></div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 0 ? 'chooseTabLiA' : ''"
                    @click="shoose(0)"
                >
                    支出
                </div>
                <div class="chooseLine"></div>
                <div
                    class="chooseTabLi"
                    :class="tabNum == 1 ? 'chooseTabLiA' : ''"
                    @click="shoose(1)"
                >
                    入账
                </div>
            </div>
        </div>
        <div class="boxMsg">
            <el-empty
                description="暂无数据"
                v-if="dataList.length <= 0"
            ></el-empty>
            <div class="boxLi" v-for="(item, index) in dataList" :key="index">
                <div class="boxDsplay">
                    <div class="boxTime">{{ item.createDate }}</div>
                    <div
                        class="boxStatus"
                        :class="item.status == -1 ? 'boxStatusA' : ''"
                    >
                        {{
                            (item.status == 0 && '待确认') ||
                            (item.status == 1 && '交易成功') ||
                            (item.status == -1 && '交易失败')
                        }}
                    </div>
                </div>
                <div class="boxDsplay">
                    <div class="boxName">{{ item.title }}</div>
                    <div class="boxName">
                        {{ (item.pm == 0 && '-') || (item.pm == 1 && '+')
                        }}{{ item.number }}
                    </div>
                </div>
                <div class="boxDsplay">
                    <div class="boxTime">{{ item.mark }}</div>
                </div>
                <!-- <div class="boxDsplay">
                <div class="boxTime">订单编号：{{item.linkId}}</div>
            </div> -->
            </div>
        </div>
        <div
            style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 16.27rem;
                margin: 0.5rem auto 0;
            "
        >
            <el-pagination
                :current-page="listQuery.page"
                :page-sizes="[10]"
                :page-size="listQuery.limit"
                :total="listQuery.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <!-- <pageIndex v-show="total>0" :total="total" :page.sync="listQuery.page" limit="10" @pagination="getInit" />-->
        </div>
    </div>
</template>

<script>
import { billList } from '@/api/user/index.js'

export default {
    data() {
        return {
            tabNum: 99,
            dataNum: 0,
            background: '#ffffff',
            color: '#000000',
            activeColor: '#00943F',
            position: 'fixed',
            listQuery: {
                page: 1,
                limit: 10,
                total: ''
            },
            dataList: []
        }
    },
    created() {
        this.listQuery.page = 1
        this.dataList = []
        this.getInit()
    },
    methods: {
        pageSizeChangeHandle(val) {
            this.listQuery.page = 1
            this.listQuery.limit = Number(val)
            this.getInit()
        },
        pageCurrentChangeHandle(val) {
            this.listQuery.page = Number(val)
            this.getInit()
        },
        shoose(num) {
            this.tabNum = num
            this.listQuery.page = 1
            this.dataList = []
            this.getInit()
        },
        getInit() {
            billList({
                pm: this.tabNum,
                page: this.listQuery.page,
                limit: this.listQuery.limit
            }).then((res) => {
                if (res.code == 0) {
                    this.listQuery.total = Number(res.data.total)
                    this.dataList = res.data.records
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 0.4rem;
}

.bgColor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
}
.chooseBig {
    width: 100%;
    padding: 0.2rem;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    // position: fixed;
    // top:0.8rem;
    // left: 0;
}
.chooseTab {
    width: 16.27rem;
    height: 1.55rem;
    border-radius: 0.26rem;
    opacity: 1;
    // border: 0.01rem solid #eaeaea;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0;

    .chooseTabLi {
        width: 2.5rem;
        text-align: center;
        color: #3c3c3c;
        font-size: 0.3rem;
        font-weight: 400;
        font-family: 'SourceHanSansCN';
        // margin-left: 1.2rem;
    }
    .chooseLine {
        width: 0.02rem;
        height: 0.22rem;
        opacity: 0.2;
        background: #555555;
    }
    .chooseTabLiA {
        color: #1b1b1b;
        font-size: 0.32rem;
        font-weight: 700;
        font-family: 'SourceHanSansCN';
        position: relative;
    }

    .chooseTabLiA::after {
        content: '';
        position: absolute;
        left: 40%;
        bottom: -0.2rem;
        // right: 35%;
        flex: 1;
        background: #239f59;
        width: 0.48rem;
        height: 0.09rem;
        border-radius: 0.08rem;
    }
}
.boxMsg {
    width: 16.27rem;
    border-radius: 0.29rem;
    opacity: 1;
    border: 0.04rem solid #ffffff;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.4rem 0 #19c2d11a;
    padding: 0 0.6rem;
    box-sizing: border-box;
    margin: 0 auto;
    .boxLi {
        padding: 0.55rem 0;
        box-sizing: border-box;
        border-bottom: 0.01rem solid #e5e5e5;
        .boxDsplay {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.12rem;
            .boxTime {
                color: #34323166;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
            .boxStatus {
                color: #02cd96;
                font-size: 0.26rem;
                font-weight: 400;
                font-family: 'SourceHanSansCN';
            }
            .boxStatusA {
                color: #ff0000;
            }
            .boxName {
                color: #343231;
                font-size: 0.3rem;
                font-weight: 700;
                font-family: 'SourceHanSansCN';
            }
        }
    }
}
</style>